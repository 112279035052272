import React, { useMemo, useEffect, useState } from "react";
import OpsBreadcrumb from "../../../components/NewBreadcrumbs";
import PageMainContent from "../../../components/PageMainContent";
import {
  getChargersAndFirmware,
  getAllDRPrograms,
} from "./chargerFirmwareService";
import ChargerFirmwareTable from "./chargerFirmwareTable";
import Loader from "../../../components/Loader";
import { useHistory, useLocation } from "react-router-dom";
import ToastMessage from "../../../components/ToastMessage";
import ConfirmDialog from "../../../components/ConfirmDialog";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";

import NewChargerFirmwareFilter from "./New_Charger_Firmware_Filter";
import NewCommonFilterDrawer from "../../../components/NewSideFIlterDrawer";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  allChargingModeFilter,
  allCountryCodeForFilter,
  allDepotTypesForFilter,
  allPowerTypeFilter,
  allHomeChargingFilter,
  cllmStatusFilter,
  commissionStatusFilter,
  publicChargingStatusFilter,
  rfidStatusFilter,
  allConnectivityStatusFilter,
  allFirmwareStatusFilter,
} from "../../../constants/FilterConstants";
import { commonApiActionCreator } from "../../../redux-state/actions";
import { getCheckedValues } from "../../../components/common-functions/CommonFunctions";
import { getData } from "../../../utils/Storage";
import { variables } from "../../../constants/Variables";
import {
  checkChargersSelected,
  checkDepotSelected,
  checkNestedFilterSelected,
  checkSingleFilterSelected,
} from "../../../components/all-filter-types/isFilteredCheckFunctions";

const ChargerFirmwareDetials = () => {
  let preferredDepotsList = getData(variables.USER_PREFERRED_DEPOTS);
  preferredDepotsList = preferredDepotsList?.length
    ? preferredDepotsList?.split(",")
    : null;
  const [activeFilters, setActiveFilters] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { saveAllCompaniesV2Global, saveAllChargersMetaGlobal } =
    bindActionCreators(commonApiActionCreator, dispatch);
  const { allCompaniesV2, isCompaniesLoaded } = useSelector(
    (state) => state.getAllCompaniesV2_Global
  );
  const { allChargersMeta, isChargersMetaLoaded } = useSelector(
    (state) => state.getAllChargersMeta
  );

  const { depotId, model, powerType, depotType, countryCode } = useSelector(
    (state) => state.chdFilter
  );
  const {
    CHARGER_REPLACEMENT,
    DISCONNECT_CHARGER,
    DISCONNECT_CHARGER_WEBSOCKET,
  } = indexedEndPoints;
  const [tableData, setTableData] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [resetPage, setResetPage] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [portCount, setPortCount] = React.useState({});
  const [isFilterDataLoaded, setFilterLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [companiesDataLoading, setCompaniesDataLoading] = React.useState(false);
  const [companiesRawData, setCompaniesRawData] = React.useState([]);
  const [depotsRawData, setDepotsRawData] = React.useState([]);
  const [isFiltered, setIsFiltered] = React.useState(false);
  const [allDepotType, setAllDepotType] = React.useState(
    JSON.parse(JSON.stringify(allDepotTypesForFilter))
  );
  const [DrawerOC, setDrawerOC] = React.useState({
    isOpen: false,
    tilte: "",
    data: {},
  });
  const [allDRPrograms, setAllDRPrograms] = useState([]);
  const [allDRProgramsFilter, setAllDRProgramsFilter] = useState([]);
  const [allDemandResponseFilter, setAllDemandResponseFilter] = useState([]);
  const [allAccounts, setAllAccounts] = useState([]);
  const [allMakes, setAllMakes] = useState([]);
  const [allPowerType, setAllPowerType] = useState(
    JSON.parse(JSON.stringify(allPowerTypeFilter))
  );
  const [countryCodes, setCountryCode] = React.useState(
    JSON.parse(JSON.stringify(allCountryCodeForFilter))
  );
  const [firmwareStatusFilter, setFirmwareStatusFilter] = useState(
    JSON.parse(JSON.stringify(allFirmwareStatusFilter))
  );

  const [connectivityStatusFilter, setConnectivityStatusFilter] = useState(
    JSON.parse(JSON.stringify(allConnectivityStatusFilter))
  );

  const [isRFIDcapable, setIsRFIDcapableFilter] = useState(
    JSON.parse(JSON.stringify(rfidStatusFilter))
  );

  const [publicChargingStatus, setPublicChargingStatus] = useState(
    JSON.parse(JSON.stringify(publicChargingStatusFilter))
  );

  const [cllmStatus, setCllmStatus] = useState(
    JSON.parse(JSON.stringify(cllmStatusFilter))
  );

  const [isReconfigureNeeded, setIsReconfigureNeeded] = React.useState(false);
  const [commissionStatus, setCommissionStatus] = useState(
    JSON.parse(JSON.stringify(commissionStatusFilter))
  );

  const [chargingModeFilter, setChargingModeFilter] = useState(
    JSON.parse(JSON.stringify(allChargingModeFilter))
  );
  const [homeChargingFilter, setHomeChargingFilter] = useState(
    JSON.parse(JSON.stringify(allHomeChargingFilter))
  );
  const [filterObject, setFilterObject] = React.useState({});
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    type: "primary",
  });
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const previousUrl = useHistory();
  const depotIdParam = searchParams.get("depotID")?.split(",");
  const firmwareStatusParams = searchParams.get("firmwareStatus")?.split(",");
  const powerTypeParams = searchParams.get("powerType")?.split(",");
  const makeModelParam = searchParams.get("soldAsIdList")?.split(",");
  const connectivityStatusParam = searchParams
    .get("connectivityStatus")
    ?.split(",");
  const isRfidCapableParam = searchParams.get("isRfidCapable")?.split(",");
  const homeChargingParam = searchParams.get("homeChargingFlag")?.split(",");
  const publicChargingStatusParam = searchParams
    .get("publicChargingStatus")
    ?.split(",");
  const cllmStatusParam = searchParams.get("cllmStatus")?.split(",");
  const chargingModeParam = searchParams.get("chargingMode")?.split(",");
  const countryCodeParam = searchParams.get("countryCode")?.split(",");
  const depotTypeParam = searchParams.get("depotType")?.split(",");
  const isReconfigureNeededParam = searchParams
    .get("isReconfigureNeeded")
    ?.split(",");
  const commissionStatusParam = searchParams
    .get("commissionStatus")
    ?.split(",");

  useEffect(() => {
    getAllDRProgramsList();
    getAllFilter();
  }, []);
  React.useEffect(() => {
    if (isCompaniesLoaded === false) {
      saveAllCompaniesV2Global();
    }
    getCompaniesRawData();
  }, [isCompaniesLoaded]);

  React.useEffect(() => {
    if (isChargersMetaLoaded === false) {
      saveAllChargersMetaGlobal();
    }
    getChargersFilterRawData();
  }, [isChargersMetaLoaded]);

  const getAllChargersAndFirmware = async (
    payload,
    queryParam,
    resetCount = false
  ) => {
    if (!payload) {
      setLoading(true);
      const res = await getChargersAndFirmware({}, queryParam);
      if (res.statusCode >= 200 && res.statusCode <= 299) {
        setTableData(
          res.data.length > rowsPerPage
            ? res.data.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : res.data
        );
        if (resetCount) {
          setTotalCount(0);
          setResetPage(true);
        }
      }
      setLoading(false);
    } else {
      for (const key in payload) {
        if (
          Array.isArray(payload[key]) &&
          payload[key].length === 1 &&
          payload[key][0] === "null"
        ) {
          delete payload[key];
        }
      }
      setLoading(true);
      const res = await getChargersAndFirmware(payload, queryParam);
      if (res.statusCode >= 200 && res.statusCode <= 299) {
        setTableData(
          res.data.length > rowsPerPage
            ? res.data.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : res.data
        );
        if (resetCount) {
          setTotalCount(0);
          setResetPage(true);
        }
      }
      setLoading(false);
    }
  };

  const getAllDRProgramsList = async () => {
    const res = await getAllDRPrograms();
    if (res?.statusCode === 200) {
      setAllDRPrograms(res?.data);
      let tempProgs = [
        {
          name: "Demand Response Programs",
          isExpanded: true,
          checked: false,
          showNotEnrolled: false, // flag to set/reset toggle in DR filter
          children: res.data?.map((row) => ({
            name: row.state,
            checked: false,
            isExpanded: true,
            children: row.programs?.map((item) => ({
              name: item.programName,
              value: item.programName,
              checked: false,
            })),
          })),
        },
      ];
      setAllDRProgramsFilter(tempProgs);
      setAllDemandResponseFilter(tempProgs);
    }
  };
  const getAllFilter = async () => {
    let queryParam = {};

    let selectedCommissionStatus = [];

    if (depotIdParam?.length) {
      queryParam["depotId"] = depotIdParam;
    } else if (preferredDepotsList?.length)
      queryParam["depotId"] = preferredDepotsList;
    if (firmwareStatusParams?.length) {
      let temp = firmwareStatusParams?.map((x) => (x == "null" ? null : x));
      queryParam["firmwareStatus"] = temp;
      firmwareStatusFilter[0].children.map((single) => {
        temp.includes(single.value)
          ? (single.checked = true)
          : (single.checked = false);
      });
      setFirmwareStatusFilter([...firmwareStatusFilter]);
    }

    if (makeModelParam?.length) {
      queryParam["soldAsIdList"] = makeModelParam;
    }

    if (powerTypeParams?.length) {
      queryParam["powerType"] = powerTypeParams;
      allPowerType[0].children.map((single) =>
        powerTypeParams?.includes(single.name)
          ? (single.checked = true)
          : (single.checked = false)
      );
      setAllPowerType([...allPowerType]);
    }

    if (depotTypeParam?.length) {
      queryParam["depotType"] = depotTypeParam;
      allDepotType[0].children.map((single) =>
        depotTypeParam.includes(single.name)
          ? (single.checked = true)
          : (single.checked = false)
      );
      setAllDepotType([...allDepotType]);
      if (depotTypeParam[0] == "null") {
        if ("depotType" in queryParam) {
          delete queryParam["depotType"];
        }
        allDepotType[0].children.map((single) => (single.checked = false));
        setAllDepotType([...allDepotType]);
      }
    }
    homeChargingFilter[0].checked = true;
    homeChargingFilter[0].children.map((single) => {
      if (homeChargingParam?.includes(single.name)) single.checked = true;
      else homeChargingFilter[0].checked = single.checked = false;
    });
    setHomeChargingFilter([...homeChargingFilter]);
    if (homeChargingParam?.length === 1)
      queryParam["isHomeChargingDepot"] = homeChargingParam[0] === "True";

    if (
      previousUrl?.location?.state?.from == "CompanyDepotDetails" ||
      previousUrl?.location?.state?.from == "billing"
    ) {
      if ("depotType" in queryParam) {
        delete queryParam["depotType"];
      }
      allDepotType[0].children.map((single) => (single.checked = false));
      setAllDepotType([...allDepotType]);
    }

    if (countryCodeParam?.length) {
      queryParam["countryCode"] = countryCodeParam;
      countryCodes[0]?.children?.map((parent) => {
        parent?.children?.map((child) => {
          if (countryCodeParam?.includes(child?.value)) {
            child.checked = true;
          }
        });
      });
      setCountryCode([...countryCodes]);
    }
    if (connectivityStatusParam?.length) {
      queryParam["connectivityStatus"] = connectivityStatusParam;
      connectivityStatusFilter[0].children.map((single) =>
        connectivityStatusParam.includes(single.name)
          ? (single.checked = true)
          : (single.checked = false)
      );
      setConnectivityStatusFilter([...connectivityStatusFilter]);
    }

    if (isRfidCapableParam?.length) {
      queryParam["isRfidCapable"] = isRfidCapableParam;
      isRFIDcapable[0].children.map((single) =>
        isRfidCapableParam.includes(single.name)
          ? (single.checked = true)
          : (single.checked = false)
      );
      setIsRFIDcapableFilter([...isRFIDcapable]);
    }

    if (publicChargingStatusParam?.length) {
      queryParam["publicChargingStatus"] = publicChargingStatusParam;
      publicChargingStatus[0].children.map((single) =>
        publicChargingStatusParam.includes(single.name)
          ? (single.checked = true)
          : (single.checked = false)
      );
      setPublicChargingStatus([...publicChargingStatus]);
    }

    if (cllmStatusParam?.length) {
      queryParam["cllmStatus"] = cllmStatusParam;
      cllmStatus[0].children.map((single) =>
        cllmStatusParam.includes(single.name)
          ? (single.checked = true)
          : (single.checked = false)
      );
      setCllmStatus([...cllmStatus]);
    }

    if (chargingModeParam?.length) {
      queryParam["chargingMode"] = chargingModeParam;
      chargingModeFilter[0].children.map((single) =>
        chargingModeParam.includes(single.name)
          ? (single.checked = true)
          : (single.checked = false)
      );
      setChargingModeFilter([...chargingModeFilter]);
    }

    if (commissionStatus[0].children.length) {
      selectedCommissionStatus = commissionStatus[0].children.reduce(
        (acc, cur) => {
          if (cur.checked) {
            acc.push(cur.value);
          }
          return acc;
        },
        []
      );
    }

    if (selectedCommissionStatus.length !== 0) {
      queryParam["commissionStatus"] = selectedCommissionStatus;
    }

    if (isReconfigureNeededParam?.length) {
      if (isReconfigureNeededParam[0] == "true") {
        queryParam["isReconfigureNeeded"] = true;
        setIsReconfigureNeeded(true);
      }
    }

    if (commissionStatusParam?.length) {
      queryParam["commissionStatus"] = commissionStatusParam?.map(
        (str) => str === "true"
      );
      commissionStatus[0].children.map((single) =>
        commissionStatusParam.includes(single.value?.toString())
          ? (single.checked = true)
          : (single.checked = false)
      );
      setCommissionStatus([...commissionStatus]);
      if (commissionStatusParam[0] == "null") {
        if ("commissionStatus" in queryParam) {
          delete queryParam["commissionStatus"];
        }
        commissionStatus[0].children.map((single) => (single.checked = false));
        setCommissionStatus([...commissionStatus]);
      }
    }
    setLoading(true);
    setFilterObject(queryParam);

    const res = await getChargersAndFirmware(
      queryParam,
      `?page=0&limit=${rowsPerPage}`
    );
    if (res.statusCode >= 200 && res.statusCode <= 299) {
      setTableData(
        res.data.length > rowsPerPage
          ? res.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : res.data
      );
      setFilterLoaded(true);
      if (Object.keys(queryParam).length !== 0) {
        setIsFiltered(true);
      }
    }
    setLoading(false);
  };

  const getCompaniesRawData = async () => {
    setCompaniesDataLoading(true);
    let tempAllCompaniesV2 = JSON.parse(JSON.stringify(allCompaniesV2));
    if (tempAllCompaniesV2.length !== 0) {
      setCompaniesRawData(tempAllCompaniesV2);
      const onlyDepotsList = [];
      tempAllCompaniesV2.map((single) => {
        if (single.depotsList) {
          onlyDepotsList.push(...single.depotsList);
        }
      });
      setDepotsRawData([...onlyDepotsList]);

      tempAllCompaniesV2?.map((single) => {
        single.checked = false;
        single.isExpanded = true;
        if ("depotsList" in single) {
          single.depotsList?.map((singleChild) => {
            singleChild["checked"] =
              preferredDepotsList?.length > 0 &&
              preferredDepotsList?.includes(singleChild.depotId);
            if (
              depotIdParam?.length &&
              depotIdParam?.includes(singleChild.depotId)
            )
              // To select particular depot in filters to show its home chargers when navigated from companies page
              singleChild["checked"] = true;
            // else if (
            //   !depotIdParam?.length &&
            //   depotId?.includes(singleChild.depotId)
            // )
            //   singleChild["checked"] = true;
          });
          if (
            getCheckedValues(single?.depotsList, "checked")?.length ==
            single?.depotsList?.length
          ) {
            single.checked = true;
          }
        }
      });
      let tempAccounts = [
        {
          name: "Account and Depot",
          isExpanded: true,
          checked: false,
          children: tempAllCompaniesV2,
        },
      ];
      setAllAccounts(tempAccounts);
    }
    setCompaniesDataLoading(false);
  };

  const getChargersFilterRawData = async () => {
    let tempAllChargersMeta = JSON.parse(JSON.stringify(allChargersMeta));
    if (tempAllChargersMeta.length !== 0) {
      tempAllChargersMeta?.map((single) => {
        single.checked = false;
        single.isExpanded = true;
        if ("chargers" in single) {
          single.chargers?.map((singleChild) => {
            singleChild["checked"] = false;
            singleChild["isExpanded"] = true;
            if ("soldAs" in singleChild) {
              singleChild?.soldAs?.map((singleSoldAs) => {
                // if (model) {
                if (
                  // model.includes(singleSoldAs.modelId) ||
                  makeModelParam?.includes(singleSoldAs.modelId)
                ) {
                  singleSoldAs.checked = true;
                } else {
                  singleSoldAs.checked = false;
                }
                // }
              });
              if (
                getCheckedValues(singleChild?.soldAs, "checked")?.length ==
                singleChild?.soldAs?.length
              ) {
                singleChild.checked = true;
              }
            }
          });
          if (
            getCheckedValues(single?.chargers, "checked")?.length ==
            single?.chargers?.length
          ) {
            single.checked = true;
          }
        }
      });
      let tempMakes = [
        {
          name: "Charger make and model",
          isExpanded: true,
          checked: false,
          children: tempAllChargersMeta,
        },
      ];
      setAllMakes(tempMakes);
    }
  };

  const applyAccountsDepotDropdown = (tempAllAccounts) => {
    applyFilter(
      tempAllAccounts,
      allMakes,
      allPowerType,
      allDepotType,
      firmwareStatusFilter,
      connectivityStatusFilter,
      publicChargingStatus,
      cllmStatus,
      isRFIDcapable,
      allChargingModeFilter,
      countryCodes,
      commissionStatus,
      allDRProgramsFilter,
      isReconfigureNeeded,
      homeChargingFilter
    );
  };

  const resetAccountsFilter = () => {
    const allAcc = [...allAccounts];
    allAcc[0].checked = false;
    allAcc[0].children.map((child) => {
      child.checked = false;
      if (child.depotsList) {
        child.depotsList.map((grandChild) => {
          grandChild.checked = false;
        });
      }
    });
    return allAcc;
  };

  const resetMakeModel = () => {
    const makeModel = [...allMakes];
    makeModel[0].checked = false;
    makeModel[0].children.map((child) => {
      child.checked = false;
      if (child.chargers) {
        child.chargers.map((grandChild) => {
          grandChild.checked = false;
          if (grandChild.soldAs) {
            grandChild.soldAs.map((grandGrandChild) => {
              grandGrandChild.checked = false;
            });
          }
        });
      }
    });
    return makeModel;
  };

  const clearAllFilters = () => {
    applyFilter(
      resetAccountsFilter(),
      resetMakeModel(),
      JSON.parse(JSON.stringify(allPowerTypeFilter)),
      JSON.parse(JSON.stringify(allDepotTypesForFilter)),
      JSON.parse(JSON.stringify(allFirmwareStatusFilter)),
      JSON.parse(JSON.stringify(allConnectivityStatusFilter)),
      JSON.parse(JSON.stringify(publicChargingStatusFilter)),
      JSON.parse(JSON.stringify(cllmStatusFilter)),
      JSON.parse(JSON.stringify(rfidStatusFilter)),
      JSON.parse(JSON.stringify(allChargingModeFilter)),
      JSON.parse(JSON.stringify(allCountryCodeForFilter)),
      JSON.parse(JSON.stringify(commissionStatusFilter)),
      allDemandResponseFilter,
      false,
      JSON.parse(JSON.stringify(allHomeChargingFilter))
    );
    setActiveFilters([]);
  };

  useEffect(() => {
    const activeFilters = [];
    if (checkSingleFilterSelected(allDepotType)) {
      activeFilters.push({
        name: "Depot Type",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            allPowerType,
            JSON.parse(JSON.stringify(allDepotTypesForFilter)),
            firmwareStatusFilter,
            connectivityStatusFilter,
            publicChargingStatus,
            cllmStatus,
            isRFIDcapable,
            allChargingModeFilter,
            countryCodes,
            commissionStatus,
            allDRProgramsFilter,
            isReconfigureNeeded,
            homeChargingFilter
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkDepotSelected(allAccounts)) {
      activeFilters.push({
        name: "Account and Depot",
        onClick: (index) => {
          applyFilter(
            resetAccountsFilter(),
            allMakes,
            allPowerType,
            allDepotType,
            firmwareStatusFilter,
            connectivityStatusFilter,
            publicChargingStatus,
            cllmStatus,
            isRFIDcapable,
            allChargingModeFilter,
            countryCodes,
            commissionStatus,
            allDRProgramsFilter,
            isReconfigureNeeded,
            homeChargingFilter
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkChargersSelected(allMakes)) {
      activeFilters.push({
        name: "Charger Make and Model",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            resetMakeModel(),
            allPowerType,
            allDepotType,
            firmwareStatusFilter,
            connectivityStatusFilter,
            publicChargingStatus,
            cllmStatus,
            isRFIDcapable,
            allChargingModeFilter,
            countryCodes,
            commissionStatus,
            allDRProgramsFilter,
            isReconfigureNeeded,
            homeChargingFilter
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkSingleFilterSelected(allPowerType)) {
      activeFilters.push({
        name: "Power Type",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            JSON.parse(JSON.stringify(allPowerTypeFilter)),
            allDepotType,
            firmwareStatusFilter,
            connectivityStatusFilter,
            publicChargingStatus,
            cllmStatus,
            isRFIDcapable,
            allChargingModeFilter,
            countryCodes,
            commissionStatus,
            allDRProgramsFilter,
            isReconfigureNeeded,
            homeChargingFilter
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkSingleFilterSelected(firmwareStatusFilter)) {
      activeFilters.push({
        name: "Firmware Status",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            allPowerType,
            allDepotType,
            JSON.parse(JSON.stringify(allFirmwareStatusFilter)),
            connectivityStatusFilter,
            publicChargingStatus,
            cllmStatus,
            isRFIDcapable,
            allChargingModeFilter,
            countryCodes,
            commissionStatus,
            allDRProgramsFilter,
            isReconfigureNeeded,
            homeChargingFilter
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkSingleFilterSelected(connectivityStatusFilter)) {
      activeFilters.push({
        name: "Connectivity Status",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            allPowerType,
            allDepotType,
            firmwareStatusFilter,
            JSON.parse(JSON.stringify(allConnectivityStatusFilter)),
            publicChargingStatus,
            cllmStatus,
            isRFIDcapable,
            allChargingModeFilter,
            countryCodes,
            commissionStatus,
            allDRProgramsFilter,
            isReconfigureNeeded,
            homeChargingFilter
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (
      checkSingleFilterSelected(isRFIDcapable) ||
      checkSingleFilterSelected(publicChargingStatus) ||
      checkSingleFilterSelected(homeChargingFilter) ||
      checkSingleFilterSelected(cllmStatus)
    ) {
      activeFilters.push({
        name: "Config Flags",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            allPowerType,
            allDepotType,
            firmwareStatusFilter,
            connectivityStatusFilter,
            JSON.parse(JSON.stringify(publicChargingStatusFilter)),
            JSON.parse(JSON.stringify(cllmStatusFilter)),
            JSON.parse(JSON.stringify(rfidStatusFilter)),
            allChargingModeFilter,
            countryCodes,
            commissionStatus,
            allDRProgramsFilter,
            isReconfigureNeeded,
            JSON.parse(JSON.stringify(allHomeChargingFilter))
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkSingleFilterSelected(chargingModeFilter)) {
      activeFilters.push({
        name: "Charging Mode",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            allPowerType,
            allDepotType,
            firmwareStatusFilter,
            connectivityStatusFilter,
            publicChargingStatus,
            cllmStatus,
            isRFIDcapable,
            JSON.parse(JSON.stringify(allChargingModeFilter)),
            countryCodes,
            commissionStatus,
            allDRProgramsFilter,
            isReconfigureNeeded,
            homeChargingFilter
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkNestedFilterSelected(countryCodes)) {
      activeFilters.push({
        name: "Country",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            allPowerType,
            allDepotType,
            firmwareStatusFilter,
            connectivityStatusFilter,
            publicChargingStatus,
            cllmStatus,
            isRFIDcapable,
            chargingModeFilter,
            JSON.parse(JSON.stringify(allCountryCodeForFilter)),
            commissionStatus,
            allDRProgramsFilter,
            isReconfigureNeeded,
            homeChargingFilter
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkSingleFilterSelected(commissionStatus)) {
      activeFilters.push({
        name: "Commission Status",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            allPowerType,
            allDepotType,
            firmwareStatusFilter,
            connectivityStatusFilter,
            publicChargingStatus,
            cllmStatus,
            isRFIDcapable,
            chargingModeFilter,
            countryCodes,
            JSON.parse(JSON.stringify(commissionStatusFilter)),
            allDRProgramsFilter,
            isReconfigureNeeded,
            homeChargingFilter
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkNestedFilterSelected(allDRProgramsFilter)) {
      activeFilters.push({
        name: "Demand Response",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            allPowerType,
            allDepotType,
            firmwareStatusFilter,
            connectivityStatusFilter,
            publicChargingStatus,
            cllmStatus,
            isRFIDcapable,
            chargingModeFilter,
            countryCodes,
            commissionStatus,
            allDemandResponseFilter,
            isReconfigureNeeded,
            homeChargingFilter
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    setActiveFilters(activeFilters);
  }, [
    allAccounts,
    allMakes,
    allPowerType,
    allDepotType,
    firmwareStatusFilter,
    connectivityStatusFilter,
    publicChargingStatus,
    cllmStatus,
    isRFIDcapable,
    chargingModeFilter,
    countryCodes,
    commissionStatus,
    allDemandResponseFilter,
    isReconfigureNeeded,
    homeChargingFilter,
  ]);

  const applyFilter = (
    tempAllAccounts,
    tempAllMakeModel,
    tempAllPowerType,
    tempAllDepotType,
    tempAllFirmwareStatusFilter,
    tempAllConnectivityStatusFilter,
    tempPublicChargingStatus,
    tempCllmStatus,
    tempIsRFIDcapable,
    tempChargingModeFilter,
    tempAllCountryCode,
    tempAllCommissionStatus,
    tempAllDemandResponse,
    tempIsReconfigureNeeded,
    tempAllHomeChargingFlag
  ) => {
    toggleDrawer(false, "FILTER", {});
    let selectedAccFilters = [];
    let selectedMakeFilters = [];
    let selectedPowerType = [];
    let selectedFirmwareStatusFilter = [];
    let selectedConnectivityStatusFilter = [];
    let selectedChargingModeFilter = [];
    let selectedCountryCodesFilter = [];
    let selectedDepotType = [];
    let selectedRFIDcapable = [];
    let selectedPublicChargingStatus = [];
    let selectedCllmStatus = [];
    let selectedCommissionStatus = [];
    let selectedDRPrograms = [];
    let selectedHomeChargingFlag = [];
    if (tempAllAccounts[0].children.length) {
      selectedAccFilters = tempAllAccounts[0].children.reduce((acc, cur) => {
        const selectedChildElements = cur.depotsList.reduce((accCh, curCh) => {
          if (curCh.checked) {
            accCh.push(curCh.depotId);
          }
          return accCh;
        }, []);
        acc.push(...selectedChildElements);
        return acc;
      }, []);
    }
    setAllAccounts([...tempAllAccounts]);
    if (tempAllMakeModel[0].children.length) {
      selectedMakeFilters = tempAllMakeModel[0].checked
        ? []
        : tempAllMakeModel[0].children.reduce((acc, cur) => {
            const selectedChildElements = cur.chargers.reduce(
              (accCh, curCh) => {
                if (curCh?.soldAs) {
                  curCh?.soldAs?.map((single) => {
                    if (single.checked) {
                      accCh.push(single?.modelId);
                    }
                  });
                }
                return accCh;
              },
              []
            );
            acc.push(...selectedChildElements);
            return acc;
          }, []);
    }
    setAllMakes([...tempAllMakeModel]);
    if (tempAllPowerType[0].children.length) {
      selectedPowerType = tempAllPowerType[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.name);
        }
        return acc;
      }, []);
    }
    setAllPowerType([...tempAllPowerType]);
    if (tempAllFirmwareStatusFilter[0].children.length) {
      selectedFirmwareStatusFilter =
        tempAllFirmwareStatusFilter[0].children.reduce((acc, cur) => {
          if (cur.checked) {
            acc.push(cur.value);
          }
          return acc;
        }, []);
    }
    setFirmwareStatusFilter([...tempAllFirmwareStatusFilter]);
    if (tempAllCountryCode[0].children.length) {
      selectedCountryCodesFilter = tempAllCountryCode[0].children.reduce(
        (codes, countryCodess) => {
          const selectedChildElements = countryCodess.children.reduce(
            (CntryCode, curCh) => {
              if (curCh.checked) {
                CntryCode.push(curCh.value);
              }
              return CntryCode;
            },
            []
          );
          codes.push(...selectedChildElements);
          return codes;
        },
        []
      );
    }
    setCountryCode([...tempAllCountryCode]);
    if (tempAllConnectivityStatusFilter[0].children.length) {
      selectedConnectivityStatusFilter =
        tempAllConnectivityStatusFilter[0].children.reduce((acc, cur) => {
          if (cur.checked) {
            acc.push(cur.value);
          }
          return acc;
        }, []);
    }
    setConnectivityStatusFilter([...tempAllConnectivityStatusFilter]);

    if (tempPublicChargingStatus[0].children.length) {
      selectedPublicChargingStatus =
        tempPublicChargingStatus[0].children.reduce((acc, cur) => {
          if (cur.checked) {
            acc.push(cur.value);
          }
          return acc;
        }, []);
    }
    setPublicChargingStatus([...tempPublicChargingStatus]);

    if (tempCllmStatus[0].children.length) {
      selectedCllmStatus = tempCllmStatus[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.value);
        }
        return acc;
      }, []);
    }
    setCllmStatus([...tempCllmStatus]);

    if (tempIsRFIDcapable[0].children.length) {
      selectedRFIDcapable = tempIsRFIDcapable[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.value);
        }
        return acc;
      }, []);
    }
    setIsRFIDcapableFilter([...tempIsRFIDcapable]);

    if (tempAllDepotType[0].children.length) {
      selectedDepotType = tempAllDepotType[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.name);
        }
        return acc;
      }, []);
    }
    setAllDepotType([...tempAllDepotType]);
    if (tempChargingModeFilter[0].children.length) {
      selectedChargingModeFilter = tempChargingModeFilter[0].children.reduce(
        (acc, cur) => {
          if (cur.checked) {
            acc.push(cur.value);
          }
          return acc;
        },
        []
      );
    }
    setChargingModeFilter([...tempChargingModeFilter]);
    if (tempAllCommissionStatus[0].children.length) {
      selectedCommissionStatus = tempAllCommissionStatus[0].children.reduce(
        (acc, cur) => {
          if (cur.checked) {
            acc.push(cur.value);
          }
          return acc;
        },
        []
      );
    }
    setCommissionStatus([...tempAllCommissionStatus]);
    if (tempAllDemandResponse[0].children.length) {
      selectedDRPrograms = tempAllDemandResponse[0].children.reduce(
        (progs, states) => {
          const selectedChildElements = states.children.reduce(
            (program, curCh) => {
              if (curCh.checked) {
                program.push(curCh.value);
              }
              return program;
            },
            []
          );
          progs.push(...selectedChildElements);
          return progs;
        },
        []
      );
      if (tempAllDemandResponse?.[0]?.showNotEnrolled) {
        selectedDRPrograms.push(null);
        selectedDRPrograms.push([]);
      }
    }
    setAllDRProgramsFilter([...tempAllDemandResponse]);
    setIsReconfigureNeeded(tempIsReconfigureNeeded);
    if (tempAllHomeChargingFlag[0].children.length) {
      selectedHomeChargingFlag = tempAllHomeChargingFlag[0].children.reduce(
        (acc, cur) => {
          if (cur.checked) {
            acc.push(cur.name);
          }
          return acc;
        },
        []
      );
    }
    setHomeChargingFilter([...tempAllHomeChargingFlag]);
    if (
      selectedAccFilters.length !== 0 ||
      selectedMakeFilters.length !== 0 ||
      selectedPowerType.length !== 0 ||
      selectedFirmwareStatusFilter.length !== 0 ||
      selectedConnectivityStatusFilter.length !== 0 ||
      selectedChargingModeFilter.length !== 0 ||
      selectedCountryCodesFilter.length !== 0 ||
      selectedDepotType.length !== 0 ||
      selectedRFIDcapable.length !== 0 ||
      selectedPublicChargingStatus.length !== 0 ||
      selectedCllmStatus.length !== 0 ||
      selectedCommissionStatus.length !== 0 ||
      selectedDRPrograms.length !== 0 ||
      selectedHomeChargingFlag.length !== 0 ||
      tempIsReconfigureNeeded == true
    ) {
      let queryParamTempObj = {};
      if (selectedAccFilters.length !== 0) {
        queryParamTempObj["depotId"] = selectedAccFilters;
      }
      if (selectedMakeFilters.length !== 0) {
        queryParamTempObj["soldAsIdList"] = selectedMakeFilters;
      }
      if (selectedPowerType.length !== 0) {
        queryParamTempObj["powerType"] = selectedPowerType;
      }
      if (selectedFirmwareStatusFilter.length !== 0) {
        queryParamTempObj["firmwareStatus"] = selectedFirmwareStatusFilter;
      }
      if (selectedConnectivityStatusFilter.length !== 0) {
        queryParamTempObj["connectivityStatus"] =
          selectedConnectivityStatusFilter;
      }
      if (selectedRFIDcapable.length !== 0) {
        queryParamTempObj["isRfidCapable"] = selectedRFIDcapable;
      }
      if (selectedPublicChargingStatus.length !== 0) {
        queryParamTempObj["publicChargingStatus"] =
          selectedPublicChargingStatus;
      }
      if (selectedCllmStatus.length !== 0) {
        queryParamTempObj["cllmStatus"] = selectedCllmStatus;
      }

      if (selectedChargingModeFilter.length !== 0) {
        queryParamTempObj["chargingMode"] = selectedChargingModeFilter;
      }
      if (selectedCountryCodesFilter.length !== 0) {
        queryParamTempObj["countryCode"] = selectedCountryCodesFilter;
      }
      if (selectedDepotType.length !== 0) {
        queryParamTempObj["depotType"] = selectedDepotType;
      }
      if (selectedCommissionStatus.length !== 0) {
        queryParamTempObj["commissionStatus"] = selectedCommissionStatus;
      }
      if (selectedDRPrograms.length !== 0) {
        queryParamTempObj["energyServicePrograms"] = selectedDRPrograms;
      }
      if (tempIsReconfigureNeeded) {
        queryParamTempObj["isReconfigureNeeded"] = true;
      }
      if (selectedHomeChargingFlag.length === 1) {
        queryParamTempObj["isHomeChargingDepot"] =
          selectedHomeChargingFlag[0] === "True";
      }
      setIsFiltered(true);
      setFilterObject(queryParamTempObj);
      getAllChargersAndFirmware(
        queryParamTempObj,
        `?page=0&limit=${rowsPerPage}`,
        true
      );

      let temp = { ...queryParamTempObj };
      if (queryParamTempObj["firmwareStatus"]) {
        temp["firmwareStatus"] = queryParamTempObj["firmwareStatus"]?.map((x) =>
          x === null ? "null" : x
        );
      }
      const res = Object.keys(temp)
        .map(
          (key) =>
            `${key}=${
              Array.isArray(temp[key]) ? temp[key].join(",") : temp[key]
            }`
        )
        .join("&");
      history.replace(`/charger-details?${res}`);
    } else {
      getAllChargersAndFirmware({}, `?page=0&limit=${rowsPerPage}`, true);
      setFilterObject({});
      setIsFiltered(false);
      history.replace(`/charger-details`);
    }
    if (allMakes[0].checked) setIsFiltered(true);
  };

  const toggleDrawer = (openClose, title, item) => {
    setDrawerOC({ isOpen: openClose, title: title, data: item });
  };

  const replaceCharger = async (item, originalChargerId) => {
    setLoading(true);
    const payload = {
      depotId: item?.depotId,
      chargerId: item?.chargerId,
      chargerName: item?.chargerName,
      chargerObjectId: item?.objectId,
      chargerMetaId: item?.chargerMetaId,
      soldAsId: item?.soldAsId,
      originalChargerId: originalChargerId,
    };
    const response = await apiProvider.put(CHARGER_REPLACEMENT, payload);
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Charger replacement successful",
        type: "success",
      });
      let data = tableData?.map((row) => {
        if (
          row.chargerId === item.chargerId &&
          row.depotId === item.depotId &&
          row.objectId === item.objectId &&
          row.chargerName === item.chargerName
        )
          row.isChargerReplaced = true;
        return row;
      });
      setTableData(data);
    } else {
      setToast({
        isOpen: true,
        message: "Charger replacement failed",
        type: "error",
      });
    }
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    setLoading(false);
  };

  useEffect(() => {
    const chargerId = Object.keys(portCount)[0];
    const ports = Object.values(portCount)[0];
    let data = tableData?.map((row) => {
      if (row.chargerId === chargerId) row.chargePortsCount = ports;
      return row;
    });
    setTableData(data);
  }, [portCount]);

  const disconnectCharger = async (item) => {
    setLoading(true);
    const response = await apiProvider.put(
      DISCONNECT_CHARGER?.replace("depotId", item.depotId)?.replace(
        "objectId",
        item.objectId
      ),
      {}
    );
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Charger disconnect successful",
        type: "success",
      });
      let data = tableData?.map((row) => {
        if (row.depotId === item.depotId && row.objectId === item.objectId)
          row.isChargerDisconnected = true;
        return row;
      });
      setTableData(data);
    } else {
      setToast({
        isOpen: true,
        message: "Charger disconnect failed",
        type: "error",
      });
    }
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    setLoading(false);
  };

  const chargerData = useMemo(() => {
    let combinedData = [];
    if (!tableData?.length || !allMakes[0]?.children?.length)
      return combinedData;
    tableData?.forEach((row) => {
      let chargerModelInfo = {};
      allMakes[0]?.children?.some((make) => {
        const matched = make.chargers?.find(
          (item) => item.chargerMetaId === row.chargerMetaId
        );
        if (matched) {
          chargerModelInfo = matched;
          return true;
        }
        return false;
      });
      let combinedObj = {
        ...row,
        firmwareStatus: row.firmwareStatus ? row.firmwareStatus : "na",
        modelMaxVoltage: chargerModelInfo.maxVoltage
          ? Number(chargerModelInfo.maxVoltage)
          : chargerModelInfo.maxVoltage,
        modelMaxAmps: chargerModelInfo.maxAmps
          ? Number(chargerModelInfo.maxAmps)
          : chargerModelInfo.maxAmps,
        modelMinChargingRate: chargerModelInfo.minChargingRate
          ? Number(chargerModelInfo.minChargingRate)
          : chargerModelInfo.minChargingRate,
        modelMaxChargingRate: chargerModelInfo.maxChargingRate
          ? Number(chargerModelInfo.maxChargingRate)
          : chargerModelInfo.maxChargingRate,
        RFIDConfig: chargerModelInfo?.rfidConfig
          ? chargerModelInfo.rfidConfig
          : [],
        typeConnector: chargerModelInfo.typeOfConnectorList,
        numberOfPorts: chargerModelInfo.numberOfPorts || 0,
        chargerRfidStatus: row?.rfidDetails?.status,
        chargerPortsName: row?.chargeports?.map((obj) => obj.portName),
      };
      if (row?.derateConfig?.isChargerDerated)
        combinedObj["deratedMaxAmps"] = row?.derateConfig?.deratedMaxAmps;
      combinedData.push(combinedObj);
    });

    return combinedData;
  }, [tableData, allMakes]);

  const disconnectChargerWebsocket = async (item) => {
    setLoading(true);
    const response = await apiProvider.put(
      DISCONNECT_CHARGER_WEBSOCKET?.replace("depotId", item.depotId)?.replace(
        "objectId",
        item.objectId
      ),
      {}
    );
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Charger websocket disconnect request successful",
        type: "success",
      });
      let data = tableData?.map((row) => {
        if (row.depotId === item.depotId && row.objectId === item.objectId)
          row.isChargerWebsocketDisconnected = true;
        return row;
      });
      setTableData(data);
    } else {
      setToast({
        isOpen: true,
        message: "Charger websocket disconnect request failed",
        type: "error",
      });
    }
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    setLoading(false);
  };

  return (
    <>
      <PageMainContent>
        <OpsBreadcrumb title="Chargers" />
        <ChargerFirmwareTable
          allChargers={chargerData}
          toggleDrawer={toggleDrawer}
          isFiltered={isFiltered}
          setConfirmDialog={setConfirmDialog}
          replaceCharger={replaceCharger}
          disconnectCharger={disconnectCharger}
          disconnectChargerWebsocket={disconnectChargerWebsocket}
          allMakes={allMakes}
          allAccounts={allAccounts}
          allDRPrograms={allDRPrograms}
          setToast={setToast}
          setLoading={setLoading}
          loading={loading}
          getAllChargersAndFirmware={getAllChargersAndFirmware}
          filterObject={filterObject}
          setPortCount={setPortCount}
          totalCount={totalCount}
          setTotalCount={setTotalCount}
          resetPage={resetPage}
          setResetPage={setResetPage}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          isFilterDataLoaded={isFilterDataLoaded}
          activeFilters={activeFilters}
          clearAllFilters={clearAllFilters}
          applyAccountsDepotDropdown={applyAccountsDepotDropdown}
        />
        <Loader isLoading={loading || companiesDataLoading} />
        <NewCommonFilterDrawer DrawerOC={DrawerOC} toggleDrawer={toggleDrawer}>
          {DrawerOC.title === "FILTER" && (
            <NewChargerFirmwareFilter
              firmwareStatusFilter={firmwareStatusFilter}
              connectivityStatusFilter={connectivityStatusFilter}
              isRFIDcapable={isRFIDcapable}
              isReconfigureNeeded={isReconfigureNeeded}
              publicChargingStatus={publicChargingStatus}
              cllmStatus={cllmStatus}
              chargingModeFilter={chargingModeFilter}
              allAccounts={allAccounts}
              allDepotType={allDepotType}
              allMakes={allMakes}
              allPowerType={allPowerType}
              applyFilter={applyFilter}
              countryCode={countryCodes}
              commissionStatus={commissionStatus}
              allDemandResponse={allDRProgramsFilter}
              allHomeChargingFlag={homeChargingFilter}
            />
          )}
        </NewCommonFilterDrawer>
      </PageMainContent>
      {confirmDialog.isOpen && (
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      )}
      <ToastMessage toast={toast} setToast={setToast} />
    </>
  );
};

export default ChargerFirmwareDetials;
