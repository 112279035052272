import React, { useState, useEffect } from "react";
import Loader from "../../../../components/Loader";
import OpsBreadcrumb from "../../../../components/NewBreadcrumbs";
import NewCommonFilterDrawer from "../../../../components/NewSideFIlterDrawer";
import PageMainContent from "../../../../components/PageMainContent";
import ToastMessage from "../../../../components/ToastMessage";
import ToastMessageSecond from "../../../../components/ToastMessageSecond";
import { getParamsUseQuery } from "../../../../utils/GetParams";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { commonApiActionCreator } from "../../../../redux-state/actions";
import {
  getOpenFaults,
  getFaultsTroubleShoot,
  faultNotes,
} from "../../ChargerHealthService";
import FaultsTable from "./FaultsTable";
import FilterFaults from "./NewFaultsFilter";
import { OpenFaultsContext } from "./OpenFaultsContext";
import {
  allCountryCodeForFilter,
  allDepotTypesForFilter,
  allFaultStatusFilter,
  allFaultsImpactFilter,
  allOpenDurationFilter,
  allPowerTypeFilter,
} from "../../../../constants/FilterConstants";
import { getCheckedValues } from "../../../../components/common-functions/CommonFunctions";
import { getData } from "../../../../utils/Storage";
import { variables } from "../../../../constants/Variables";
import {
  checkChargersSelected,
  checkDepotSelected,
  checkNestedFilterSelected,
  checkSingleFilterSelected,
} from "../../../../components/all-filter-types/isFilteredCheckFunctions";

const AllBreadcrumbsLinks = [
  {
    link: "/charger-health",
    title: "Charger Health",
  },
];

const FaultsOpenFaults = () => {
  let preferredDepotsList = getData(variables.USER_PREFERRED_DEPOTS);
  preferredDepotsList = preferredDepotsList?.length
    ? preferredDepotsList?.split(",")
    : null;
  const [activeFilters, setActiveFilters] = useState([]);
  const dispatch = useDispatch();
  const { saveAllCompaniesV2Global, saveAllChargersMetaGlobal } =
    bindActionCreators(commonApiActionCreator, dispatch);
  const { allCompaniesV2, isCompaniesLoaded } = useSelector(
    (state) => state.getAllCompaniesV2_Global
  );
  const { allChargersMeta, isChargersMetaLoaded } = useSelector(
    (state) => state.getAllChargersMeta
  );
  const { depotId, model, make, powerType, depotType, countryCode } =
    useSelector((state) => state.chdFilter);
  const [toast, setToast] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [toastSecond, setToastSecond] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [tableLoaded, setTableLoaded] = React.useState(false);
  const [correctData, setCorrectData] = React.useState(false);
  const [openFaultsRawData, setOpenFaultsRawData] = React.useState([]);
  const [companiesRawData, setCompaniesRawData] = React.useState([]);
  const [depotsRawData, setDepotsRawData] = React.useState([]);
  const [faults, setFaults] = React.useState([]);
  const [troubleShootData, setTroubleShootData] = React.useState([]);
  const [isTroubleShootDataLoading, setTroubleShootDataLoading] =
    React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  let depotIdParam = getParamsUseQuery().get("depotId");
  let chargerIdParam = getParamsUseQuery().get("chargerId");
  let severity = getParamsUseQuery().get("severity");
  let timeSlot = getParamsUseQuery().get("timeSlot")
    ? getParamsUseQuery().get("timeSlot").replace(/\s/g, "")
    : getParamsUseQuery().get("timeSlot");
  const [allAccounts, setAllAcconts] = React.useState([]);
  const [allMakes, setAllMakes] = React.useState([]);
  const [allPowerType, setAllPowerType] = React.useState(
    JSON.parse(JSON.stringify(allPowerTypeFilter))
  );
  const [allDepotType, setAllDepotType] = React.useState(
    JSON.parse(JSON.stringify(allDepotTypesForFilter))
  );
  const [allFaultsImpact, setAllFaultImpact] = React.useState(
    JSON.parse(JSON.stringify(allFaultsImpactFilter))
  );

  const [allFaultStatus, setAllFaultStatus] = useState([
    {
      name: "Status",
      isExpanded: true,
      checked: false,
      children: [
        {
          name: "Open",
          checked: true,
          value: "Open",
        },
        {
          name: "Closed",
          checked: false,
          value: "Closed",
        },
      ],
    },
  ]);

  const [allOpenDuration, setAllOpenDuration] = useState(
    JSON.parse(JSON.stringify(allOpenDurationFilter))
  );
  const [countryCodes, setCountryCodes] = React.useState(
    JSON.parse(JSON.stringify(allCountryCodeForFilter))
  );

  useEffect(() => {
    if (depotsRawData.length !== 0) {
      getFaultsData();
    }
  }, [depotsRawData]);

  React.useEffect(() => {
    if (isChargersMetaLoaded === false) {
      saveAllChargersMetaGlobal();
    }
    getChargersFilter();
  }, [isChargersMetaLoaded]);

  React.useEffect(() => {
    if (isCompaniesLoaded === false) {
      saveAllCompaniesV2Global();
    }
    getAccountsAndFilter();
  }, [isCompaniesLoaded]);

  const getFaultsData = () => {
    let newFilterObject = {};
    if (chargerIdParam || depotIdParam) {
      if (chargerIdParam) newFilterObject["chargerId"] = chargerIdParam;
      if (depotIdParam) newFilterObject["depotId"] = [depotIdParam];
    } else {
      if (depotId) newFilterObject["depotId"] = [...depotId];
      if (model) newFilterObject["soldAsIdList"] = model;
      if (powerType) {
        newFilterObject["powerType"] = [...powerType];
        allPowerType[0].children.map((single) =>
          powerType.includes(single.name)
            ? (single.checked = true)
            : (single.checked = false)
        );
        setAllPowerType([...allPowerType]);
      }
      if (countryCode.length !== 0) {
        newFilterObject["countryCode"] = [...countryCode];
        countryCodes[0]?.children?.map((parent) => {
          parent?.children?.map((child) => {
            if (countryCode?.includes(child?.value)) {
              child.checked = true;
            }
          });
        });
        setCountryCodes([...countryCodes]);
      }
      if (depotType.length !== 0) {
        newFilterObject["depotType"] = [...depotType];
        allDepotType[0].children.map((single) =>
          depotType.includes(single.name)
            ? (single.checked = true)
            : (single.checked = false)
        );
        setAllDepotType([...allDepotType]);
      }
    }
    if (!severity) {
      newFilterObject["severity"] = ["Medium", "High", "Unknown"];
      allFaultsImpact[0].children.map((single) =>
        ["Medium", "High", "Unknown"].includes(single.name)
          ? (single.checked = true)
          : (single.checked = false)
      );
      setAllFaultImpact([...allFaultsImpact]);
    }
    if (severity) {
      if (severity === "medium") {
        newFilterObject["severity"] = ["Medium"];
        allFaultsImpact[0].children[
          allFaultsImpact[0].children.findIndex((x) => x.name == "Medium")
        ].checked = true;
        setAllFaultImpact([...allFaultsImpact]);
      } else if (severity === "high") {
        newFilterObject["severity"] = ["High"];
        allFaultsImpact[0].children[
          allFaultsImpact[0].children.findIndex((x) => x.name == "High")
        ].checked = true;
        setAllFaultImpact([...allFaultsImpact]);
      } else if (severity === "unknown") {
        newFilterObject["severity"] = ["Unknown"];
        allFaultsImpact[0].children[
          allFaultsImpact[0].children.findIndex((x) => x.name == "Unknown")
        ].checked = true;
        setAllFaultImpact([...allFaultsImpact]);
      }
    }
    if (timeSlot) {
      if (timeSlot === "1hr") {
        newFilterObject["time"] = [{ low: "0m", high: "1h" }];
        changeOpenDurationInFilter("0 - 1 hours");
      }
      if (timeSlot === "2hr") {
        newFilterObject["time"] = [{ low: "1h", high: "2h" }];
        changeOpenDurationInFilter("1 - 2 hours");
      }
      if (timeSlot === "4hr") {
        newFilterObject["time"] = [{ low: "2h", high: "4h" }];
        changeOpenDurationInFilter("2 - 4 hours");
      }
      if (timeSlot === "8hr") {
        newFilterObject["time"] = [{ low: "4h", high: "8h" }];
        changeOpenDurationInFilter("4 - 8 hours");
      }
      if (timeSlot === "12hr") {
        newFilterObject["time"] = [{ low: "8h", high: "12h" }];
        changeOpenDurationInFilter("8 - 12 hours");
      }
      if (timeSlot === "24hr") {
        newFilterObject["time"] = [{ low: "12h", high: "24h" }];
        changeOpenDurationInFilter("12 - 24 hours");
      }
      if (timeSlot === "48hr") {
        newFilterObject["time"] = [{ low: "24h", high: "48h" }];
        changeOpenDurationInFilter("24 - 48 hours");
      }
      if (timeSlot === "1week") {
        newFilterObject["time"] = [{ low: "48h", high: "7d" }];
        changeOpenDurationInFilter("48 hours - 1 week");
      }
      if (timeSlot === "1month") {
        newFilterObject["time"] = [{ low: "7d", high: "30d" }];
        changeOpenDurationInFilter("1 week - 1 month");
      }
      if (timeSlot === "2months") {
        newFilterObject["time"] = [{ low: "30d", high: "60d" }];
        changeOpenDurationInFilter("1 month - 2 months");
      }
      if (timeSlot === "3months") {
        newFilterObject["time"] = [{ low: "60d", high: "180d" }];
        changeOpenDurationInFilter("2 months - 3+ months");
      }
    }
    newFilterObject["status"] = ["Open"];
    getOpenFaultsWithDepots(newFilterObject);
  };

  const changeOpenDurationInFilter = (tochage) => {
    allOpenDuration[0].children[
      allOpenDuration[0].children.findIndex((x) => x.name == tochage)
    ].checked = true;
    setAllOpenDuration([...allOpenDuration]);
  };

  useEffect(() => {
    const tempData = [];
    tableData.forEach((single) => {
      single["accountName"] =
        companiesRawData.find((x) => x.companyId === single.customerId) ===
        undefined
          ? "-"
          : companiesRawData.find((x) => x.companyId === single.customerId)
              .companyName;
      single["depotName"] =
        depotsRawData.find((x) => x.depotId === single.depotId) === undefined
          ? "-"
          : depotsRawData.find((x) => x.depotId === single.depotId).depotName;
      single["apiUrl"] =
        depotsRawData.find((x) => x.depotId === single.depotId) === undefined
          ? "-"
          : depotsRawData.find((x) => x.depotId === single.depotId).apiUrl;
      single["selected"] = false; // this will be used for checkbox in open faults page
      tempData.push(single);
    });
    tempData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    setFaults(tempData);
  }, [tableData, depotsRawData]);

  const getOpenFaultsWithDepots = async (filter = {}) => {
    let tempFilter = JSON.parse(JSON.stringify(filter));
    setSelectedFilter(tempFilter);
    setTableLoaded(false);
    const res = await getOpenFaults(tempFilter);
    const notesReponse = await faultNotes();
    if (res?.statusCode === 200 && notesReponse?.statusCode === 200) {
      setOpenFaultsRawData(res?.data);
      const depotList = [];
      for (const [key, value] of Object.entries(res?.data)) {
        if (res?.data[key]) {
          if (res?.data[key].high.length !== 0) {
            depotList.push(...res?.data[key].high);
          }
        }
        if (res?.data[key]) {
          if (res?.data[key].medium.length !== 0) {
            depotList.push(...res?.data[key].medium);
          }
        }
        if (res?.data[key]) {
          if (res?.data[key].unknown.length !== 0) {
            depotList.push(...res?.data[key].unknown);
          }
        }
        if (res?.data[key]) {
          if (res?.data[key].diagnostic.length !== 0) {
            depotList.push(...res?.data[key].diagnostic);
          }
        }
        if (res?.data[key]) {
          if (res?.data[key].low?.length !== 0) {
            depotList.push(...res?.data[key]?.low);
          }
        }
      }
      let notesData = notesReponse.data;
      let depotsList = depotList.map((itm) => {
        return {
          ...notesData.find((item) => item.faultId === itm.faultId && item),
          ...itm,
        };
      });
      setTableData(depotsList);
      setCorrectData(true);
    } else setCorrectData(false);
    setTableLoaded(true);
  };

  const getTroubleShootData = async (row) => {
    const payload = {
      VendorErrorCode: row.vendorErrorCode,
      ErrorCode: row.errorCode,
      ChargerMetaId: row.chargerMetaId,
      SoldAsId: row.soldAsId,
    };
    setTroubleShootDataLoading(true);
    const res = await getFaultsTroubleShoot(payload);
    setTroubleShootDataLoading(false);
    if (res?.statusCode === 200) {
      const data = res?.data?.body[0];
      const finalData = {};
      Object.entries(data)?.forEach(([key, value]) => {
        const newKey = key[0].toLowerCase() + key.slice(1);
        if (newKey === "resolution") finalData[newKey] = Object.values(value);
        else finalData[newKey] = value;
      });
      setTroubleShootData(finalData);
    }
  };

  const [DrawerOC, setDrawerOC] = React.useState({
    isOpen: false,
    tilte: "",
    data: {},
  });

  const getAccountsAndFilter = async () => {
    let tempAllCompaniesV2 = JSON.parse(JSON.stringify(allCompaniesV2));

    if (tempAllCompaniesV2.length !== 0) {
      setCompaniesRawData(tempAllCompaniesV2);
      const onlyDepotsList = [];
      tempAllCompaniesV2.map((single) => {
        if (single.depotsList) {
          onlyDepotsList.push(...single.depotsList);
        }
      });

      setDepotsRawData([...onlyDepotsList]);
      setCorrectData(true);

      tempAllCompaniesV2?.map((single) => {
        single.checked = true;
        single.isExpanded = true;
        if ("depotsList" in single) {
          single.depotsList?.map((singleChild) => {
            if (depotIdParam) {
              if (singleChild.depotId == depotIdParam) {
                singleChild["checked"] = true;
              } else {
                singleChild["checked"] = false;
              }
            } else {
              if (depotId) {
                if (depotId.includes(singleChild.depotId)) {
                  singleChild["checked"] = true;
                } else {
                  singleChild["checked"] = false;
                }
              } else {
                singleChild["checked"] =
                  preferredDepotsList?.length > 0 &&
                  preferredDepotsList?.includes(singleChild.depotId);
              }
            }
            if (singleChild["checked"] === false) single.checked = false;
          });
        }
      });
      let tempAccounts = [
        {
          name: "Account and Depot",
          isExpanded: true,
          checked: false,
          children: tempAllCompaniesV2,
        },
      ];
      setAllAcconts(tempAccounts);
    } else setCorrectData(false);
    setTableLoaded(true);
  };

  const getChargersFilter = () => {
    let tempAllChargersMeta = JSON.parse(JSON.stringify(allChargersMeta));
    if (tempAllChargersMeta.length !== 0) {
      tempAllChargersMeta?.map((single) => {
        single.checked = false;
        single.isExpanded = true;
        if ("chargers" in single) {
          single.chargers?.map((singleChild) => {
            singleChild.checked = false;
            singleChild.isExpanded = true;
            if ("soldAs" in singleChild) {
              singleChild?.soldAs?.map((singleSoldAs) => {
                if (model) {
                  if (model.includes(singleSoldAs.modelId)) {
                    singleSoldAs.checked = true;
                  } else {
                    singleSoldAs.checked = false;
                  }
                }
              });
              if (
                getCheckedValues(singleChild?.soldAs, "checked")?.length ==
                singleChild?.soldAs?.length
              ) {
                singleChild.checked = true;
              }
            }
          });

          if (
            getCheckedValues(single?.chargers, "checked")?.length ==
            single?.chargers?.length
          ) {
            single.checked = true;
          }
        }
      });
      let tempMakes = [
        {
          name: "Charger make and model",
          isExpanded: true,
          checked: false,
          children: tempAllChargersMeta,
        },
      ];
      setAllMakes(tempMakes);
    }
  };

  const resetAccountsFilter = () => {
    const allAcc = [...allAccounts];
    allAcc[0].checked = false;
    allAcc[0].children.map((child) => {
      child.checked = false;
      if (child.depotsList) {
        child.depotsList.map((grandChild) => {
          grandChild.checked = false;
        });
      }
    });
    return allAcc;
  };

  const resetMakeModel = () => {
    const makeModel = [...allMakes];
    makeModel[0].checked = false;
    makeModel[0].children.map((child) => {
      child.checked = false;
      if (child.chargers) {
        child.chargers.map((grandChild) => {
          grandChild.checked = false;
          if (grandChild.soldAs) {
            grandChild.soldAs.map((grandGrandChild) => {
              grandGrandChild.checked = false;
            });
          }
        });
      }
    });
    return makeModel;
  };

  const clearAllFilters = () => {
    applyFilter(
      resetAccountsFilter(),
      resetMakeModel(),
      JSON.parse(JSON.stringify(allPowerTypeFilter)),
      JSON.parse(JSON.stringify(allFaultsImpactFilter)),
      JSON.parse(JSON.stringify(allDepotTypesForFilter)),
      JSON.parse(JSON.stringify(allFaultStatusFilter)),
      JSON.parse(JSON.stringify(allOpenDurationFilter)),
      JSON.parse(JSON.stringify(allCountryCodeForFilter))
    );
    setActiveFilters([]);
  };

  useEffect(() => {
    const activeFilters = [];
    if (checkSingleFilterSelected(allDepotType)) {
      activeFilters.push({
        name: "Depot Type",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            allPowerType,
            allFaultsImpact,
            JSON.parse(JSON.stringify(allDepotTypesForFilter)),
            allFaultStatus,
            allOpenDuration,
            countryCodes
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkDepotSelected(allAccounts)) {
      activeFilters.push({
        name: "Account and Depot",
        onClick: (index) => {
          applyFilter(
            resetAccountsFilter(),
            allMakes,
            allPowerType,
            allFaultsImpact,
            allDepotType,
            allFaultStatus,
            allOpenDuration,
            countryCodes
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkChargersSelected(allMakes)) {
      activeFilters.push({
        name: "Charger Make and Model",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            resetMakeModel(),
            allPowerType,
            allFaultsImpact,
            allDepotType,
            allFaultStatus,
            allOpenDuration,
            countryCodes
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkSingleFilterSelected(allPowerType)) {
      activeFilters.push({
        name: "Power Type",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            JSON.parse(JSON.stringify(allPowerTypeFilter)),
            allFaultsImpact,
            allDepotType,
            allFaultStatus,
            allOpenDuration,
            countryCodes
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkSingleFilterSelected(allFaultsImpact)) {
      activeFilters.push({
        name: "Fault Impact",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            allPowerType,
            JSON.parse(JSON.stringify(allFaultsImpactFilter)),
            allDepotType,
            allFaultStatus,
            allOpenDuration,
            countryCodes
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkSingleFilterSelected(allFaultStatus)) {
      activeFilters.push({
        name: "Status",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            allPowerType,
            allFaultsImpact,
            allDepotType,
            JSON.parse(JSON.stringify(allFaultStatusFilter)),
            allOpenDuration,
            countryCodes
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkSingleFilterSelected(allOpenDuration)) {
      activeFilters.push({
        name: "Open Duration",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            allPowerType,
            allFaultsImpact,
            allDepotType,
            allFaultStatus,
            JSON.parse(JSON.stringify(allOpenDurationFilter)),
            countryCodes
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    if (checkNestedFilterSelected(countryCodes)) {
      activeFilters.push({
        name: "Country",
        onClick: (index) => {
          applyFilter(
            allAccounts,
            allMakes,
            allPowerType,
            allFaultsImpact,
            allDepotType,
            allFaultStatus,
            allOpenDuration,
            JSON.parse(JSON.stringify(allCountryCodeForFilter))
          );
          activeFilters.splice(index, 1);
        },
      });
    }
    setActiveFilters(activeFilters);
  }, [
    allAccounts,
    allMakes,
    allPowerType,
    allFaultsImpact,
    allDepotType,
    allFaultStatus,
    allOpenDuration,
    countryCodes,
  ]);

  const applyFilter = (
    tempAllAccounts,
    tempAllMakeModel,
    tempAllPowerType,
    tempAllFaultsImpact,
    tempAllDepotType,
    tempAllFaultStatus,
    tempAllOpenDuration,
    tempCountryCode
  ) => {
    const filterObj = createFilterPayload(
      tempAllAccounts,
      tempAllMakeModel,
      tempAllPowerType,
      tempAllFaultsImpact,
      tempAllDepotType,
      tempAllFaultStatus,
      tempAllOpenDuration,
      tempCountryCode
    );
    getOpenFaultsWithDepots(filterObj);
    toggleDrawer(false, "", {});
  };
  const createFilterPayload = (
    tempAllAccounts,
    tempAllMakeModel,
    tempAllPowerType,
    tempAllFaultsImpact,
    tempAllDepotType,
    tempAllFaultStatus,
    tempAllOpenDuration,
    tempCountryCode
  ) => {
    let selectedAccFilters = [];
    let selectedModelFilters = [];
    let selectedPowerType = [];
    let selectedImpactFilters = [];
    let selectFaultSatuts = [];
    let selectedTimeFilters = [];
    let selectedCountryCode = [];
    let selectedDepotType = [];
    if (tempAllAccounts[0].children.length) {
      selectedAccFilters = tempAllAccounts[0].children.reduce((acc, cur) => {
        const selectedChildElements = cur.depotsList.reduce((accCh, curCh) => {
          if (curCh.checked) {
            accCh.push(curCh.depotId);
          }
          return accCh;
        }, []);
        acc.push(...selectedChildElements);
        return acc;
      }, []);
    }
    setAllAcconts([...tempAllAccounts]);
    if (tempAllMakeModel[0].children.length) {
      selectedModelFilters = tempAllMakeModel[0].checked
        ? []
        : tempAllMakeModel[0].children.reduce((acc, cur) => {
            const selectedChildElements = cur.chargers.reduce(
              (accCh, curCh) => {
                if (curCh?.soldAs) {
                  curCh?.soldAs?.map((single) => {
                    if (single.checked) {
                      accCh.push(single?.modelId);
                    }
                  });
                }
                return accCh;
              },
              []
            );
            acc.push(...selectedChildElements);
            return acc;
          }, []);
    }
    setAllMakes([...tempAllMakeModel]);
    if (tempAllPowerType[0].children.length) {
      selectedPowerType = tempAllPowerType[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.name);
        }
        return acc;
      }, []);
    }
    setAllPowerType([...tempAllPowerType]);
    if (tempAllFaultsImpact[0].children.length) {
      selectedImpactFilters = tempAllFaultsImpact[0].children.reduce(
        (acc, cur) => {
          if (cur.checked) {
            acc.push(cur.name);
          }
          return acc;
        },
        []
      );
    }
    setAllFaultImpact([...tempAllFaultsImpact]);
    if (tempAllDepotType[0].children.length) {
      selectedDepotType = tempAllDepotType[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.name);
        }
        return acc;
      }, []);
    }
    setAllDepotType([...tempAllDepotType]);
    if (tempAllFaultStatus[0].children.length) {
      selectFaultSatuts = tempAllFaultStatus[0].children.reduce((acc, cur) => {
        if (cur.checked) {
          acc.push(cur.value);
        }
        return acc;
      }, []);
    }
    setAllFaultStatus([...tempAllFaultStatus]);
    if (tempCountryCode[0].children.length) {
      selectedCountryCode = tempCountryCode[0].children.reduce(
        (codes, countryCodess) => {
          const selectedChildElements = countryCodess.children.reduce(
            (CntryCode, curCh) => {
              if (curCh.checked) {
                CntryCode.push(curCh.value);
              }
              return CntryCode;
            },
            []
          );
          codes.push(...selectedChildElements);
          return codes;
        },
        []
      );
    }
    setCountryCodes([...tempCountryCode]);
    if (tempAllOpenDuration[0].children.length) {
      selectedTimeFilters = tempAllOpenDuration[0].children.reduce(
        (acc, cur) => {
          if (cur.checked) {
            acc.push(cur.value);
          }
          return acc;
        },
        []
      );
    }
    setAllOpenDuration([...tempAllOpenDuration]);
    return {
      depotId: [...selectedAccFilters],
      soldAsIdList: [...selectedModelFilters],
      powerType: [...selectedPowerType],
      severity: [...selectedImpactFilters],
      status: [...selectFaultSatuts],
      time: [...selectedTimeFilters],
      countryCode: [...selectedCountryCode],
      depotType: [...selectedDepotType],
    };
  };

  const toggleDrawer = (openClose, title, item) => {
    setDrawerOC({ isOpen: openClose, title: title, data: item });
  };

  return (
    <>
      <OpenFaultsContext.Provider
        value={{
          depotIdParam,
          timeSlot,
          severity,
        }}
      >
        <OpsBreadcrumb
          AllBreadcrumbsLinks={AllBreadcrumbsLinks}
          title="Fault Report"
        />

        {tableLoaded && correctData ? (
          <PageMainContent>
            <FaultsTable
              toggleDrawer={toggleDrawer}
              faults={faults}
              setFaults={setFaults}
              isTroubleShootDataLoading={isTroubleShootDataLoading}
              troubleShootData={troubleShootData}
              getTroubleShootData={getTroubleShootData}
              selectedFilter={selectedFilter}
              isFiltered={
                allMakes[0]?.checked ||
                Object.values(selectedFilter).filter((e) => e.length).length !==
                  0
              }
              getOpenFaultsWithDepots={getOpenFaultsWithDepots}
              setToast={setToast}
              setToastSecond={setToastSecond}
              activeFilters={activeFilters}
              clearAllFilters={clearAllFilters}
            />
            <NewCommonFilterDrawer
              DrawerOC={DrawerOC}
              toggleDrawer={toggleDrawer}
            >
              <FilterFaults
                allAccounts={allAccounts}
                allMakes={allMakes}
                allPowerType={allPowerType}
                allFaultsImpact={allFaultsImpact}
                allDepotType={allDepotType}
                allFaultStatus={allFaultStatus}
                allOpenDuration={allOpenDuration}
                applyFilter={applyFilter}
                countryCode={countryCodes}
              />
            </NewCommonFilterDrawer>
          </PageMainContent>
        ) : (
          <Loader isLoading={true} />
        )}
        <ToastMessage toast={toast} setToast={setToast} />
        <ToastMessageSecond toast={toastSecond} setToast={setToastSecond} />
      </OpenFaultsContext.Provider>
    </>
  );
};

export default FaultsOpenFaults;
