import React from "react";
import "../vehicleDiscovery.scss";
import { Grid, Tooltip, Divider } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentCard from "../../../components/ContentCard";

const DebuggingCheckListInformation = [
  {
    helper:
      "It is the most basic requirement that the vehicle should exist in our vehicle collection in DB",
    title: "Vehicle assigned to depot in FPC",
    description: `A vehicle must be added to the "Vehicles" tab in FPC for discovery to be possible.`,
  },
  {
    helper:
      "We can also verify this by looking for VIN in the AWS DynamoDB table or node-feed-reader logs",
    title: "Vehicle data being sent from TMC",
    description: `FPC must receive vehicle telematics data from TMC to make
              discovery possible. Mandatory for AC charger and optional for DC/
              Gen 2 AC charger (EVCCID capable).`,
  },
  {
    helper: "We do not receive telematics data for non Ford vehicles",
    title: "Ford vehicles only",
    description:
      "We are currently capable of identifying only Ford vehicles (and on demand for Geotab).",
  },
  {
    helper:
      "Only relevant in case of vehicle not discovered when connecting to DC/Gen 2 AC charger (EVCCID capable)",
    title: "VIN-VID Mapping",
    description:
      "The vehicle must have its VIN associated with its VID in the Ops Console Data Catalog. Refer to the CHECK VIN-VID section in the Vehicle Discovery tab to verify.",
  },
  {
    helper: "It may lead to failure in discovery because of timing mismatch",
    title: "RFID",
    description:
      "Check whether or not RFID was used to authorize the session. This could cause a gap between the session-preparing message and when the vehicle actually plugs in.",
  },
  {
    helper: "Ensure charger location is set for greater accuracy",
    title: "Charger Location",
    description:
      "Setting the charger location will increase the likelihood of discovery by geofencing. The default 300 meter radius within the depot location is used if it is not set.",
  },
  {
    helper: "Because there is no business use case of it yet",
    title: "Shared/Public charging session",
    description:
      "We do not conduct vehicle discovery on public charging sessions.",
  },
  {
    helper:
      "Some of them are not in our control, and there is always a chance of failure",
    title: "Hardware issues",
    description:
      "Some chargers are not configured to send an ID tag. Other problems such as overheating issues, firmware issues, soft-resetting issues, time-drift issues, etc. have also been observed.",
  },
];

export default function DebuggingCheckList() {
  return (
    <>
      <ContentCard>
        <Grid container mb={1}>
          <Grid item xs>
            <div className="title_for_chargersCount">
              <p className="metrics_titles">
                Vehicle Discovery Debugging Checklist
                <Tooltip title="A concise checklist of items that can help to improve the overall vehicle discovery">
                  <span className="info">
                    <InfoIcon fontSize="small" className="infoIconColor" />
                  </span>
                </Tooltip>
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <div className="title_for_checkList">
              <p className="metrics_titles">
                <Tooltip title={DebuggingCheckListInformation[0].helper}>
                  <span className="success">
                    <CheckCircleIcon
                      fontSize="small"
                      className="successIconColor"
                    />
                  </span>
                </Tooltip>
                {DebuggingCheckListInformation[0].title}
              </p>
            </div>
            <p className="metrics_description">
              {DebuggingCheckListInformation[0].description}
            </p>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            className="dividerVertical"
          />
          <Grid item xs={6}>
            <div className="title_for_checkList">
              <p className="metrics_titles">
                <Tooltip title={DebuggingCheckListInformation[1].helper}>
                  <span className="success">
                    <CheckCircleIcon
                      fontSize="small"
                      className="successIconColor"
                    />
                  </span>
                </Tooltip>
                {DebuggingCheckListInformation[1].title}
              </p>
            </div>
            <p className="metrics_description">
              {DebuggingCheckListInformation[1].description}
            </p>
          </Grid>
          <Divider variant="middle" className="dividerHorizontal" />
          <Grid item xs={6}>
            <div className="title_for_checkList">
              <p className="metrics_titles">
                <Tooltip title={DebuggingCheckListInformation[2].helper}>
                  <span className="success">
                    <CheckCircleIcon
                      fontSize="small"
                      className="successIconColor"
                    />
                  </span>
                </Tooltip>
                {DebuggingCheckListInformation[2].title}
              </p>
            </div>
            <p className="metrics_description">
              {DebuggingCheckListInformation[2].description}
            </p>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            className="dividerVertical"
          />
          <Grid item xs={6}>
            <div className="title_for_checkList">
              <p className="metrics_titles">
                <Tooltip title={DebuggingCheckListInformation[3].helper}>
                  <span className="success">
                    <CheckCircleIcon
                      fontSize="small"
                      className="successIconColor"
                    />
                  </span>
                </Tooltip>
                {DebuggingCheckListInformation[3].title}
              </p>
            </div>
            <p className="metrics_description">
              {DebuggingCheckListInformation[3].description}
            </p>
          </Grid>
          <Divider variant="middle" className="dividerHorizontal" />
          <Grid item xs={6}>
            <div className="title_for_checkList">
              <p className="metrics_titles">
                <Tooltip title={DebuggingCheckListInformation[4].helper}>
                  <span className="success">
                    <CheckCircleIcon
                      fontSize="small"
                      className="successIconColor"
                    />
                  </span>
                </Tooltip>
                {DebuggingCheckListInformation[4].title}
              </p>
            </div>
            <p className="metrics_description">
              {DebuggingCheckListInformation[4].description}
            </p>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            className="dividerVertical"
          />
          <Grid item xs={6}>
            <div className="title_for_checkList">
              <p className="metrics_titles">
                <Tooltip title={DebuggingCheckListInformation[5].helper}>
                  <span className="success">
                    <CheckCircleIcon
                      fontSize="small"
                      className="successIconColor"
                    />
                  </span>
                </Tooltip>
                {DebuggingCheckListInformation[5].title}
              </p>
            </div>
            <p className="metrics_description">
              {DebuggingCheckListInformation[5].description}
            </p>
          </Grid>
          <Divider variant="middle" className="dividerHorizontal" />
          <Grid item xs={6}>
            <div className="title_for_checkList">
              <p className="metrics_titles">
                <Tooltip title={DebuggingCheckListInformation[6].helper}>
                  <span className="error">
                    <CancelIcon fontSize="small" className="errorIconColor" />
                  </span>
                </Tooltip>
                {DebuggingCheckListInformation[6].title}
              </p>
            </div>
            <p className="metrics_description">
              {DebuggingCheckListInformation[6].description}
            </p>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            className="dividerVertical"
          />
          <Grid item xs={6}>
            <div className="title_for_checkList">
              <p className="metrics_titles">
                <Tooltip title={DebuggingCheckListInformation[7].helper}>
                  <span className="error">
                    <CancelIcon fontSize="small" className="errorIconColor" />
                  </span>
                </Tooltip>
                {DebuggingCheckListInformation[7].title}
              </p>
            </div>
            <p className="metrics_description">
              {DebuggingCheckListInformation[7].description}
            </p>
          </Grid>
        </Grid>
      </ContentCard>
    </>
  );
}
